import React, { useState } from "react";
import * as Styled from "../css/searchStyle";
import TextField from "@material-ui/core/TextField";
import { useGlobal } from "reactn";
import { AllSearchQuery } from "../components/scripts/searchbarQuery";
import { Link } from "gatsby";

const Search = ({ searchMenu }) => {
  let [english] = useGlobal("English");
  let [SearchKeyword, setSearchKeyword] = useState("");

  return (
    <Styled.SearchContainer searchMenu={searchMenu}>
      <Styled.SearchInput>
        <TextField
          id="outlined-search"
          label={english ? "Search" : "Sök"}
          type="search"
          margin="normal"
          variant="outlined"
          size="small"
          onChange={e => setSearchKeyword(e.target.value)}
          value={SearchKeyword}
        />
      </Styled.SearchInput>
      <Styled.SearchUL>
        <SearchRender SearchKeyword={SearchKeyword} />
      </Styled.SearchUL>
    </Styled.SearchContainer>
  );
};

export default Search;

const SearchRender = ({ SearchKeyword }) => {
  let data;
  if (SearchKeyword !== "") {
    data = AllSearchQuery()
      .filter(i => i.namn.includes(SearchKeyword.trim().toLowerCase()))
      .map((i, index) => {
        let nameCapitalized = i.namn.charAt(0).toUpperCase() + i.namn.slice(1);
        return (
          <li key={index}>
            <Link className={"textDecorationNone"} to={i.url}>
              <Styled.SearchMatch>
                <Styled.SearchMatchName>
                  {nameCapitalized}
                </Styled.SearchMatchName>
                <br />
                <Styled.SearchMatchURL>{i.url}</Styled.SearchMatchURL>
              </Styled.SearchMatch>
            </Link>
          </li>
        );
      });
  } else {
    data = null;
  }

  return data;
};
