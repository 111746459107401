import Styled from "styled-components";

export const Footer = Styled.div`
    margin-top: 1%;
    @media(max-width: 600px){
      margin-top: 2%;
    }
    background-color: #181818;
    color: #f1f1f1;
    * p {
        font-family:StureplansgruppenUnderRubrik !important;
       }
    
    hr{
      margin:0 auto!important;
    }
    a{
        color: #f3f3f3;    
        *:hover{
        text-decoration: underline;
        cursor: pointer;
        color: #f3f3f3;  

    }    
    }    
    }

    input[type="email"]{
        max-width: 250px;
        margin: auto;

        ::placeholder{
          color: #cccccc;
      }
    }
    a {
        :hover{
            text-decoration: underline;
            ursor: pointer;
            color: #f3f3f3;  
        }
    }
    h5{
      @media screen and (max-width: 767px){
        margin: 1rem auto !important;
        padding:0;
       }
      }
    .list-unstyled{
      p{
        margin-bottom: 2px;
      }     
       p{
        @media screen and (max-width: 767px){
        margin: 2px 0;
       }
      }
    }
`;
