import React, { useState } from "react";
import * as Styled from "../css/footerStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icon from "@fortawesome/free-brands-svg-icons";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useGlobal } from "reactn";

const btnStyle = {
  maxWidth: "250px",
  background: "#f5f5f5"
};
const Footer = () => {
  const [english] = useGlobal("English");
  const [subscribeMail, setValue] = useState("");
  const [userMessage, setUserMessage] = useState(null);

  let FooterData = useStaticQuery(
    graphql`
      query {
        contentfulFooter {
          telefonnummer
          email
          adress {
            childContentfulRichText {
              html
            }
          }
          lnkTillPolicys
          instagramUrl
          facebookUrl
        }
      }
    `
  );
  let { telefonnummer, email, adress } = FooterData.contentfulFooter;

  let PhoneTo = telefonnummer.replace("-", "").replace(/ /g, "");
  PhoneTo = "tel:" + PhoneTo;

  // call mail api
  let subscribe = async event => {
    event.preventDefault();
    let result = await addToMailchimp(subscribeMail, {
      'group[15281][1]': 1
    });
    result.msg = result.msg.split("<")[0];
    setUserMessage(result.msg);
    setValue('');
  };
  //gets mail from form
  const onChange = event => setValue(event.target.value);

  return (
    <Styled.Footer onSubmit={subscribe} className="page-footer mdb-color pt-4">
      <div className="container text-center text-md-left">
        <div className="row">
          <div className="col-md-4 col-lg-3 mr-auto my-md-4 my-0 mt-4 mb-1">
            <h5 className="font-weight-bold text-uppercase mb-4">
              {english
                ? "KEEP UP WITH ENTERTAINMENT"
                : "FÖLJ MED I NÖJESVÄRLDEN"}
            </h5>
            <p>
              {english
                ? " Sign up and get our inspirational newsletter sent out every Friday!"
                : "Anmäl dig till vårt inspirerande nyhetsbrev som skickas ut varje fredag!"}
            </p>
            <p>{userMessage}</p>
            <form>
              <div
                style={{
                  position: "absolute",
                  left: "-5000px"
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_c1b9099d8cadfa2979fd02116_536216a00b"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  left: "-5000px"
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_c1b9099d8cadfa2979fd02116_536216a00b"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
              <div className="row" id="mc_embed_signup_scroll">
                <div className="col-sm-12">
                  <div className="content">
                    <div className="form-group input-group mc-field-group">
                      <input
                        onChange={onChange}
                        type="email"
                        value={subscribeMail}
                        className="form-control"
                        placeholder={
                          english ? "Enter your email" : "Skriv in din epost"
                        }
                      />
                    </div>
                    <div className="mc-field-group input-group">
                      <input
                        type="checkbox"
                        defaultValue="1"
                        name="group[15281][1]"
                        id="mce-group[15281]-15281-0"
                        defaultChecked
                        style={{ display: "none" }}
                      />
                    </div>
                    <div id="mce-responses">
                      <div
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <button
                      style={btnStyle}
                      className="btn col-sm-6 col-md-12"
                      type="submit"
                      id="mc-embedded-subscribe"
                    >
                      {english ? "Sign up here!" : "Anmäl dig här!"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4 col-lg-3 mx-auto my-md-4 my-0 mt-4 mb-1">
            <h5 className="font-weight-bold text-uppercase mb-4">
              {english ? "Address" : "Adress"}
            </h5>

            <ul className="list-unstyled">
              <li>
                <div
                  dangerouslySetInnerHTML={{
                    __html: adress.childContentfulRichText.html
                  }}
                />
              </li>
              <li>
                <p>
                  <a name="email" href={"MailTo:" + email}>
                    {email}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a name="phone" href={PhoneTo}>
                    {telefonnummer}
                  </a>
                </p>
              </li>
            </ul>
          </div>

          <div className="col-md-2 col-lg-2 mx-auto my-md-4 my-0 mt-4 mb-1">
            <h5 className="font-weight-bold text-uppercase mb-4">
              {english ? "About" : "om"}
            </h5>

            <ul className="list-unstyled">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jobb.stureplansgruppen.se/"
                >
                  <p>{english ? "Career" : "Jobb"}</p>
                </a>
              </li>
              <li>
                <Link to="/press/">
                  <p>Press</p>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <p>{english ? "Contact" : "Kontakt"}</p>
                </Link>
              </li>
              <li>
                <Link to="/stureplansgruppen">
                  <p>{english ? "About us" : "Om oss"}</p>
                </Link>
              </li>
              <li>
                <Link to="/policies">
                  <p>{english ? "Policies" : "Policyer"}</p>
                </Link>
              </li>
              <li>
                <Link to="https://entrgroup.se/lost-and-found/">
                  <p>{english ? "Lost and found" : "Borttappat"}</p>
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-2 col-lg-2 text-center mx-auto my-4">
            <h5 className="font-weight-bold text-uppercase mb-4">
              {english ? "Follow us" : "Följ oss"}
            </h5>

            <a
              target="_bank"
              className="btn-floating btn"
              href="https://www.facebook.com/stureplansgruppen"
            >
              <FontAwesomeIcon size="3x" icon={icon.faFacebook} />
            </a>

            <a
              target="_bank"
              className="btn-floating btn"
              href="https://www.instagram.com/stureplansgruppen/"
            >
              <FontAwesomeIcon size="3x" icon={icon.faInstagram} />
            </a>
          </div>
        </div>
      </div>
    </Styled.Footer>
  );
};

export default Footer;
