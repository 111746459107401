import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import { useGlobal } from "reactn";
import * as Styled from "../css/headerStyle";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import Searchicon from "../assets/icons/searchicon.png";
import Search from "../components/search";

const Header2 = () => {
  const [english, setLanguage] = useGlobal("English");
  const [yscroll, setYscroll] = useState(0);
  const [searchMenu, SetsearchMenu] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setYscroll(window.pageYOffset);
    });
  }, []);

  let logga = useStaticQuery(
    graphql`
      query {
        contentfulLogga {
          logga {
            fixed(width: 50, height: 50) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    `
  );

  logga = logga.contentfulLogga.logga.fixed;
  

  return (
    <Styled.NavbarContainer boxShadow={yscroll}>
      <Search
      searchMenu={searchMenu}
      />
      <Navbar bg="#F8F8F8" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Link to="/" id="mobillogga">
          <Img fixed={logga} />
        </Link>

        <Styled.Language>
          <button type="button" onClick={() => setLanguage(false)} >
            <p>SV</p>
          </button>
          <span>|</span>
          <button type="button" onClick={() => setLanguage(true)} >
            <p>EN</p>
          </button>
          <button
          type="button"
          onClick={() => SetsearchMenu(!searchMenu)}
          onKeyDown={() => SetsearchMenu(!searchMenu)}
          >
          <img
            src={Searchicon}
            alt="sreach icon"
          />
          </button>
          
        </Styled.Language>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Styled.Header>
              <Link to="/" id="desktoplogga">
                <Img fixed={logga} />
              </Link>

              <ul>
                
                <Link
                  to="/hotel"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "HOTEL" : "HOTELL"}
                </Link>

                <Link
                  to="/restaurant"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "RESTAURANT" : "RESTAURANG"}
                </Link>

                <Link
                  to="/bar"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "BAR" : "BAR"}
                </Link>

                <Link
                  to="https://entrgroup.se"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "NIGHTLIFE" : "NATTLIV"}
                </Link>
                  <Link
                  to="/event"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "EVENT" : "EVENT"}
                </Link>
                <Link
                  to="/lokal"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "VENUES" : "LOKALER"}
                </Link>
                <Link
                  to="/news"
                  partiallyActive={true}
                  activeStyle={{ textDecoration: "underline" }}
                >
                  {english ? "NEWS" : "NYHETER"} 
                </Link>
                
              </ul>
            </Styled.Header>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styled.NavbarContainer>
  );
};

export default Header2;
