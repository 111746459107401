import { useStaticQuery, graphql } from "gatsby";
import { useGlobal } from "reactn";

export const AllSearchQuery = () => {
  let [english] = useGlobal("English");
  let lang = (english ? 'en' : 'sv')


  const data = useStaticQuery(
    graphql`
      query {
        allContentfulEnheter {
          edges {
            node {
              namn
              slug
              sida
              node_locale
            }
          }
        }
        allContentfulEventEnheter {
          edges {
            node {
              namn
              slug
              node_locale
            }
          }
        }
        allContentfulLokaler {
          edges {
            node {
              namn
              slug
              node_locale
            }
          }
        }
        allContentfulNyheter {
          edges {
            node {
              rubrik
              slug
              node_locale
            }
          }
        }
        allContentfulSmallTalks {
          edges {
            node {
              rubrik
              slug
              node_locale
            }
          }
        }
      }
    `
  );
  
  let AllData = data.allContentfulEnheter.edges.filter(i => i.node.node_locale === lang).map(i => {
    let urlfind = "";
    
    if (i.node.sida.includes("Restaurang")) {
      urlfind = "/restaurant/";
    } else if (i.node.sida.includes("Bar")) {
      urlfind = "/bar/";
    } else if (i.node.sida.includes("Nattliv")) {
      urlfind = "/nightlife/";
    } else if (i.node.sida.includes("Hotell")) {
      urlfind = "/hotel/";
    } else {
      urlfind = "";
    }
    
    return {
      namn: i.node.namn.trim().toLowerCase(),
      url: urlfind + i.node.slug
    };
  });


  data.allContentfulEventEnheter.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.namn.trim().toLowerCase(),
      url: '/event/' + i.node.slug
    })
  })
  data.allContentfulLokaler.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.namn.trim().toLowerCase(),
      url: '/event/venues/' + i.node.slug
    })
  })
  data.allContentfulNyheter.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.rubrik.trim().toLowerCase(),
      url: '/news/' + i.node.slug
    })
  })
  data.allContentfulSmallTalks.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.rubrik.trim().toLowerCase(),
      url: '/news/' + i.node.slug
    })
  })
  data.allContentfulSmallTalks.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.rubrik.trim().toLowerCase(),
      url: '/smalltalks/' + i.node.slug
    })
  })
  data.allContentfulSmallTalks.edges.filter(i => i.node.node_locale === lang).map( i => {
    return AllData.push({
      namn: i.node.rubrik.trim().toLowerCase(),
      url: '/smalltalks/' + i.node.slug
    })
  })

  return AllData;
};
