import styled from "styled-components";

export const Language = styled.div`
  position: absolute;
  top: 13px;
  right: 20px;
  display: flex;
  height: auto;
  cursor: pointer;
  z-index: 1;
  align-items: baseline;

  button {
    background-color: transparent;
    padding:0 2px;
    p {
      padding: 3px;
      opacity: 0.8;
      transition: opacity 0.35s, transform 0.35s;
      font-family: StureplansgruppenUnderRubrik;
      margin-bottom: 0;
    }

    p:hover {
      border-bottom: 1px solid black;
    }
  }

  img {
    width: 18px;
    height: 18px;
    margin: 0px 0 4px 5px;
  }

  @media only screen and (max-width: 992px) {
    height: 3vh;
    font-size: 20px;
    right: 5px;
    img {
      margin: 0 0 0 5px;
    }
  }
`;

export const Header = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;

  ul {
    padding: 5px 0 0 0;
    margin: 0;
    a {
      color: black;
      padding: 0 20px;
      font-family: StureplansgruppenUnderRubrik;
    }
  }

  @media only screen and (max-width: 992px) {
    ul {
      display: flex;
      flex-direction: column;
      padding: 20px 0 10px 0;
      align-items: center;
      a {
        padding: 0 5px 0 0;
        font-size: 1.2em;
      }
    }
  }
`;
export const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #f8f8f8;
  width: 100%;
  box-shadow: ${props =>
    props.boxShadow
      ? "0 4px 8px -1px rgba(0,0,0,0.12), 0 2px 4px -2px rgba(0,0,0,0.09)"
      : null};

  nav {
    padding: 0px;
    padding-top: 20px;
  }
  #mobillogga {
    display: none;
  }
  @media only screen and (max-width: 992px) {
    nav {
      padding: 10px 0 10px 0;
    }
    
    .navbar-toggler {
      margin-left: 15px;
    }
    #mobillogga {
      display: block;
      position: relative;
      right: calc(50% - 25px);
      
    }
    #desktoplogga {
      display: none;
    }
  }
`;
