import styled from "styled-components";

export const SearchContainer = styled.div`
  position:fixed;
  right:0;
  top:75px;
  transition: width 0.5s;
  width:${props => props.searchMenu ? '400px' : '0px'};
  height:100vh;
  background-color:#f8f8f8;
  z-index:1;
  box-shadow: 5px 0 5px -5px #333;
  overflow-x: scroll;
  @media screen and (max-width: 992px) {
    width:${props => props.searchMenu ? '100vw' : '0px'};
  }
`;

export const SearchInput = styled.div`
  width:90%;
  margin: 0.5vh auto 0 auto;
div {
  width:100%;
}
`

export const SearchMatch = styled.div`
padding: 5px 0 5px 15px;
  
`
export const SearchMatchName = styled.span`
font-family: StureplansgruppenUnderRubrik;
margin: 0 0 3px 0;
display:inline;

`
export const SearchMatchURL = styled.span`
font-family: Stureplansgruppen;
font-size:15px;
margin:0;
display:inline;

`

export const SearchUL = styled.ul`
    list-style: none;
    padding: 0;
 li:nth-child(even){
   background-color:white;
   cursor: pointer;
 }
`